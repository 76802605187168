.introduction {
    /* text-align: left; */
    margin: 20px;
}

.instructions {
    max-width: 650px;
    margin: 0 auto;
}

.instructions li {
    padding: 8px;
    text-align: left;
}
