@font-face {
  font-family: "quadraat";
  src: local("quadraat"),
    url("../fonts/quadraat.ttf") format("truetype");
}

.App {
  text-align: center;
  font-family: "quadraat";
}

p,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  /* overflow-wrap: break-word; */
  /* hyphens: auto; */
  /* margin:0; */
  line-height: calc(.125rem + 2ex + .125rem);
}

h1,
h2,
h3
{
  font-weight: 200;
}

/* adding a `modular scale` for headings based on a perfect fifth - source: https://www.modularscale.com/?16&px&1.5*/
h1 {
  font-size: 3.375rem;
}
h2 {
  font-size: 2.25rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1rem;
}
h5 {
  font-size: 0.667rem;
}

.flex {
  display: flex;
}

.flex-ctr-ctr {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-left {
  text-align: left;
}

.hidden {
  display: none;
}

button {
  background-color: var(--red);
  color: white;
  height: 30px;
  border: none;
  border-radius: 4px;
}

button:hover {
  background-color: var(--dark-red);
  cursor: pointer;
}

button:disabled {
  background-color: grey;
}

button:disabled:hover {
  cursor:not-allowed;
}

input {
  height: 30px;
  max-width: auto;
  padding: 8px 10px;
  margin: 5px 0px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.tooltip-wrap {
  position: relative;
}
.tooltip-wrap .tooltip-content {
  display: none;
  position: absolute;
  z-index: 5;
  /* bottom: 5%;
  left: 5%;
  right: 5%; */
  bottom: 58%;
  left: 58%;
  right: 58%;
  background-color: #fff;
  padding: .5em;
  min-width: 10rem;
  border-radius: 25px;
}
.tooltip-wrap:hover .tooltip-content {
  display: block;
  border-radius: 15px;
  border: 1px solid var(--navy-blue);
  padding: 0;
  font-size: 12px;
  opacity: 75%;
}