footer {
    bottom: 0;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-left: 25px;
    padding-right: 25px;
    border-top: 2px solid lightgrey;
    border-bottom: 2px solid lightgray;
}

footer a {
    text-decoration: none;
    color: var(--navy-blue);
}

footer a:hover, .cookie-icon:hover {
    color: var(--red);
    transition: 0.15s;
}

.cookie-icon {
    color: var(--navy-blue)
}