.loader {
  width: 1.5rem;
  height: 1.5rem;
  animation: loading infinite 3s linear;
  margin-top: 3vh;
  margin-bottom: 3vh;
  max-height: 1.5rem;
  max-width: 1.5rem;
}

@keyframes loading {
  from {
    transform: rotateZ(0deg);
  }to{
    transform: rotateZ(360deg);
  }
}
