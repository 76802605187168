.login-form {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 5px;
    padding: 10px;
    width: 85vw;
    
}

/* input styling done in app.css */

.login-form > label {
    font-size: 18px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}